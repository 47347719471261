@import './../utilities/breackpoints.scss';

.flex {
  display: flex;
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.row-sm {
  flex-direction: row;
}
.align-center {
  align-items: center !important;
}

.align-left,
.align-start {
  align-items: start !important;
}

.align-end {
  align-items: end;
}

.align-self-end {
  align-self: end;
}
.align-self-center {
  align-self: center;
}

.justify-center {
  justify-content: center;
}
.justify-left {
  justify-content: left !important;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-end {
  justify-content: flex-end;
}

.justify-evenly {
  justify-content: space-evenly;
}
.grow-1 {
  flex-grow: 1;
}
.grow-2 {
  flex-grow: 2;
}
.grow-3 {
  flex-grow: 3;
}
.grow-4 {
  flex-grow: 4;
}
.grow-5 {
  flex-grow: 5;
}
.flex-none {
  flex: none !important;
}
.flex-0 {
  flex: 0 !important;
}
.flex-05 {
  flex: 0.5 !important;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2 !important;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}

.gap-20 {
  gap: 20px;
}

.h-100 {
  height: 100px;
}

.h-460 {
  height: 460px;
}

.w-320 {
  width: 320px;
}
.w-300 {
  width: 300px;
}
.w-328 {
  width: 328px;
}

.w-390 {
  width: 390px;
}

.mw-390 {
  min-width: 300px;
  width: 100%;
  max-width: 390px;
}

.w-full {
  width: 100%;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}
@include devices(laptop, desktop) {
  .mw-100 {
    max-width: 100px;
  }
}
@include devices(mobile, tablet) {
  .row {
    flex-direction: column;
  }
  .row-sm {
    flex-direction: row;
  }
  .w-sm-full {
    width: 100%;
  }
}
