@import '../utilities/breackpoints.scss';
//Stepper
.mat-stepper-horizontal {
  max-width: 924px;
  border-radius: 4px;
  width: 100%;
  padding: 25px 10px 0 10px;
  box-sizing: border-box;
  .mat-horizontal-stepper-header-container {
    display: inherit;
    border: 1px solid var(--blue-50);
    border-radius: 8px;
    margin-bottom: 8px;
    .mat-horizontal-stepper-header {
      width: 50%;
      height: 110px;
      padding: 12px 8px;
    }
  }
}
.hide-step {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

@include devices(mobile) {
  .mat-stepper-horizontal {
    width: 100%;
  }
}
