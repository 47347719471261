@import '../../../assets/styles/utilities/breackpoints.scss';
:root {
  .modal-dialog-wrap {
    min-width: 280px;
    padding: 0 20px 30px;
    overflow-y: auto;
    max-height: 85vh;
    .close-button {
      float: right;
      color: #959595;
      margin-right: -17px;
    }
    .mat-mdc-card {
      background-color: var(--light-grey);
    }
  }
}

@include devices(mobile, tablet) {
  :root {
    .modal-dialog-wrap {
      min-width: 230px;
      height: 100%;
      max-height: 100%;
    }
  }
}
