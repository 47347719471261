@import '../utilities/breackpoints.scss';
.paragraphs {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  width: 320px;
  text-justify: auto;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}
//Weight
.bold {
  font-weight: bold;
}
.weight-400 {
  font-weight: 400 !important;
}

.weight-500 {
  font-weight: 500 !important;
}

.weight-600 {
  font-weight: 600;
}

.weight-normal {
  font-weight: normal;
}

//Color
.black {
  color: var(--black);
}

.gray-title {
  color: #495752;
}

.grey-alt {
  color: #97aca9;
}
@include devices(mobile, tablet) {
  .paragraphs {
    width: 300px;
  }
  .mat-typography h1 {
    width: 80%;
  }
  .mat-typography .h1-sm {
    width: 100%;
    text-align: center;
  }
  .w-full-sm {
    width: 100%;
  }
}
