@use '@angular/material' as mat;
@include mat.core();

//Primary Palette defined:
$custom-palette: (
  /*--blue #3c81bb*/ 900: rgba(60, 129, 187, 1),
  /*--blue-50*/ 800: rgba(60, 129, 187, 0.5),
  /*--light-blue-text*/ 700: #57b3ff,
  /*--dark-grey*/ 600: #3c81bb,
  /*--dark-grey2*/ 500: #3d3d3d,
  /* --gray-visited*/ 400: rgba(210, 210, 215, 0.25),
  /*--grey-alt*/ 300: #97aca9,
  /*--light-grey_alt*/ 200: #d2d2d7,
  /* --light-blue-hover*/ 100: #ebf3f5,
  /*--grey*/ 50: rgb(112, 119, 135),
  contrast: (
    /*--white*/ 900: #fff,
    /*--grey-10:*/ 800: rgba(112, 119, 135, 0.1),
    /*grey-text*/ 200: #6d7874,
    /*--black-span*/ 100: #333,
    /*--black*/ 50: #000
  )
);
//icon Palette defined:
$custom-icon-palette: (
  /*dark-grey-font:*/ 900: #565656,
  /*--light-blue */ 800: #8bd0f0,
  /*--yellow*/ 700: #ffbf5c,
  /*--yellow_alt */ 750: #efc285,
  /*--light-grey*/ 600: #f7f8f8,
  /*--red*/ 500: #de3a3a,
  /*--notifications*/ 450: #e25241,
  /*--green*/ 400: #2bc457,
  /*--light-blue-text*/ 300: #57b3ff,
  /*dark-grey-font for icons*/ 200: #565656,
  /*--gray*/ 100: rgb(210, 210, 215),
  /*--white*/ 50: #fff,
  contrast: (
    /*--white*/ 900: #fff,
    /*--dark-grey*/ 400: #434343,
    /*--black*/ 50: #000
  )
);

$custom-gradiant-palette: (
  /*Fondo login*/ background-gradiant:
    linear-gradient(145deg, rgba(60, 129, 187, 1) 0%, rgba(21, 54, 92, 1) 100%),
  /*color spinner*/ background-spinner:
    linear-gradient(145deg, rgba(60, 129, 187, 1) 0%, rgba(21, 54, 92, 1) 100%),
  /*fondo sidebar y header*/ header-bg:
    linear-gradient(272deg, #15365c, #3c81bb),
  contrast: (
    900: #fff,
  )
);
//For each palette, you can optionally specify a default, lighter, and darker
//These four hues represent, in order: the "default" hue, a "lighter" hue, a "darker" hue, and a "text" hue.
$agd-transporte-primary: mat.define-palette(
  $custom-palette,
  900,
  500,
  400,
  100
);
$agd-transporte-accent: mat.define-palette(
  mat.$blue-palette,
  900,
  500,
  300,
  100
);
$agd-transporte-icons: mat.define-palette(
  $custom-icon-palette,
  900,
  500,
  400,
  100
);
$agd-transporte-gradiants: mat.define-palette(
  $custom-gradiant-palette,
  background-gradiant,
  background-spinner,
  header-bg
);

/*Uso de la paleta: importar este archivo y usar
mat.get-color-from-palette($agd-transporte-primary, 300)*/
//typography defined:
$my-typography: mat.define-typography-config(
  $font-family: "'Lato', sans-serif",
  $headline-1: /*Titulo Header navbar*/
    mat.define-typography-level(
      $font-size: 13px,
      $font-weight: 500,
      $line-height: 32px,
      $letter-spacing: normal,
    ),
  $headline-2: /*Titulo de pagina*/
    mat.define-typography-level(
      $font-size: 13px,
      $font-weight: 500,
      $line-height: 32px,
      $letter-spacing: normal,
    ),
  $headline-3:
    mat.define-typography-level(
      $font-size: 24px,
      $font-weight: 500,
      $line-height: 30px,
      $letter-spacing: normal,
    ),
  $headline-4:
    mat.define-typography-level(
      $font-size: 34px,
      $font-weight: 400,
      $line-height: 40px,
      $letter-spacing: normal,
    ),
  $headline-5: /* letras H1*/
    mat.define-typography-level(
      $font-size: 24px,
      $font-weight: 400,
      $line-height: 32px,
      $letter-spacing: normal,
    ),
  $headline-6: /* letras H2*/
    mat.define-typography-level(
      $font-size: 20px,
      $font-weight: 400,
      $line-height: 32px,
      $letter-spacing: normal,
    ),
  $body-1: /* letras span typo-1 para tablas */
    mat.define-typography-level(
      $font-size: 16px,
      /*size-16*/ $font-weight: 400,
      $line-height: 20px,
      $letter-spacing: normal,
    ),
  $body-2: /* letras inputs, textos emergentes*/
    mat.define-typography-level(
      $font-size: 15px,
      /*size-15*/ $font-weight: 400,
      $line-height: 24px,
      $letter-spacing: normal,
    ),
  $caption: /* letras span size-16*/
    mat.define-typography-level(
      $font-size: 13px,
      /*size-13*/ $font-weight: 400,
      $letter-spacing: normal,
    ),
);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// The warn palette is optional (defaults to red).
$agd-transporte-theme: mat.define-light-theme(
  (
    color: (
      primary: $agd-transporte-primary,
      accent: $agd-transporte-accent,
    ),
    typography: $my-typography,
  )
);

// Include theme styles for core and each component used in the app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($agd-transporte-theme);
@include mat.typography-hierarchy($my-typography);

//REFERENCIAS DE COLORES
/*
  --font-primary: 'Lato', sans-serif;
  --white: #ffffff;
  --black: #333333;
  --black-span: #333;
  --blue: rgba(60, 129, 187, 1);
  --blue-50: rgba(60, 129, 187, 0.5);
  --green: #2bc457;
  --gray: rgb(210, 210, 215);
  --red: #de3a3a;
  --yellow: #ffbf5c;
  --yellow_alt: #efc285;
  --light-grey: #f7f8f8;
  --light-grey_alt: #d2d2d7;
  --lilac: #b055d5;
  --grey: rgb(112, 119, 135);
  --grey-10: rgba(112, 119, 135, 0.1);
  --grey-text: #6d7874;
  --grey-alt: #97aca9;
  --dark-grey: #434343;
  --dark-grey2: #3d3d3d;
  --dark-grey-font: #565656;
  --light-blue: #8bd0f0;
  --light-blue-text: #57b3ff;
  --light-blue-hover: #ebf3f5;
  --light-blue-10: rgba(60, 129, 187, 0.1);
  --notifications: #e25241;
  --gray-visited: rgba(210, 210, 215, 0.25);
  --background: linear-gradient(
    145deg,
    rgba(60, 129, 187, 1) 0%,
    rgba(21, 54, 92, 1) 100%
  );
  --header-bg: linear-gradient(272deg, #15365c, #3c81bb);
*/
