@import '../utilities/breackpoints.scss';
//Containers & Wrappers
.outer-container {
  min-height: 101vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: var(--background);
  padding: 10px;
  box-sizing: border-box;
}

.forms-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 380px;
  height: fit-content;
  padding: 30px 10px 30px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--white);
  border-radius: 4px;
}

.header-container {
  width: 100%;
}

.simple-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  padding-top: 5px;
  margin: 0px 30px 0px 30px;
}

.sidebar-header {
  background-image: var(--header-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  min-height: 185px;
  height: 25%;
}

@include devices(mobile) {
  .forms-container {
    width: 300px;
    padding: 18px 10px 18px;
  }

  .form-wrapper {
    width: 100% !important;
  }
}
